<template>
    <div class="module-data">
        <a-radio-group
            :value="current"
            :style="{ marginBottom: '10px' }"
            @change="changeNav"
        >
            <div class="title">
                <span>{{ title }}</span>
            </div>

            <a-radio-button value="0"> 全部 </a-radio-button>
            <a-radio-button value="1"> 今天 </a-radio-button>
            <a-radio-button value="2"> 近7天 </a-radio-button>
            <a-radio-button value="3"> 近30天 </a-radio-button>
            <a-radio-button value="4"> 自定义时间 </a-radio-button>

            <a-range-picker
                v-if="current == 4"
                class="time"
                :show-time="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                :placeholder="['开始日期', '结束日期']"
                @ok="changeDateTime"
            >
                <a-tooltip>
                    <template slot="title" v-if="this.startTime && this.endTime"> {{ this.startTime }} ~ {{ this.endTime }} </template>
                    <template slot="title" v-else> 点击选择时间 </template>
                    <a-icon class="time-icon" type="clock-circle" />
                </a-tooltip>
            </a-range-picker>
        </a-radio-group>

        <div class="data-content no-conversion">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModuleData",
    props: {
        title: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            current: "0",

            startTime: "",
            endTime: "",
        };
    },
    methods: {
        // 修改导航
        changeNav(e) {
            const current = e.target.value;

            this.current = current;

            if (current < 4) {
                this.startTime = '';
                this.endTime = ''
                this.$emit("changeNav", current);
            }
        },

        // 修改时间
        changeDateTime(dates) {
            this.startTime = dates[0].format("YYYY-MM-DD HH:mm:ss");
            this.endTime = dates[1].format("YYYY-MM-DD HH:mm:ss");

            this.$emit('changeTime', { current: this.current, startTime: this.startTime, endTime: this.endTime })
        },
    },
};
</script>

<style lang="less" scoped>
.module-data {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;

    .title {
        font-size: 26px;
        font-weight: bold;
        margin-right: 20px;
        display: inline-block;
    }

    .time {
        width: auto !important;
        margin-left: 20px;
    }

    .time-icon {
        font-size: 18px;
    }

    .data-content {
        width: 100%;
        // overflow: hidden;
    }
    .no-conversion {
        min-height: 280px;
    }
}
</style>