import api from './api'
import { request } from './request'

// 获取全部统计
function getTotalData(startTime, endTime) {
    return request({
        url: api.dataManage.total,
        method: 'post',
        data: { startTime, endTime }
    })
}

// 获取销售数据
function getTotalSale(startTime, endTime) {
    return request({
        url: api.dataManage.totalSale,
        method: 'post',
        data: { startTime, endTime }
    })
}

// 获取商家统计
function getBusinessData(pageNumber, pageSize, params) {
    return request({
        url: api.dataManage.business,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

// 获取商品统计
function getGoodsData(pageNumber, pageSize, params) {
    return request({
        url: api.dataManage.goods,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

// 获取门店统计
function getStoreData(pageNumber, pageSize, params) {
    return request({
        url: api.dataManage.store,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

// 获取货柜统计
function getMachineData(pageNumber, pageSize, params) {
    return request({
        url: api.dataManage.machine,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

export {
    getTotalData,
    getTotalSale,
    getBusinessData,
    getGoodsData,
    getStoreData,
    getMachineData
}