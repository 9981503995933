<template>
    <div class="machine-module">
        <module-data
            :title="'货柜排行榜'"
            @changeNav="changeNav"
            @changeTime="changeTime"
        >
            <a-table
                :columns="columns"
                :pagination="{
                    current: page,
                    pageSize: pageSize,
                    total: totalSize,
                }"
                :data-source="list"
                :scroll="scroll"
                :loading="loading"
                :row-key="(record) => record.id"
                @change="changePage"
            />
        </module-data>
    </div>
</template>

<script>
import ModuleData from "./ModelData";

import { getMachineData } from "network/dataManage";

export default {
    name: 'MachineModule', // 门店排行
    components: {
        ModuleData,
    },
    data() {
        return {
            currentIndex: "0",
            startTime: "",
            endTime: "",

            columns: [
                {
                    title: "排名",
                    dataIndex: "top",
                    key: "top",
                    width: 60,
                },
                {
                    title: "货柜",
                    dataIndex: "machine",
                    key: "machine",
                    width: 100
                },
                {
                    title: "所属商家",
                    dataIndex: "business",
                    key: "business",
                    width: 100,
                },
                {
                    title: "销售额",
                    dataIndex: "sale",
                    key: "sale",
                    width: 100,
                },
                {
                    title: "订单量",
                    dataIndex: "order",
                    key: "order",
                    width: 100,
                },
            ],
            scroll: {
                y: 202,
            },

            list: [],

            page: 1,
            pageSize: 5,
            totalSize: 0,
            loading: false,
        };
    },
    created() {
        this.loadData(this.page);
    },
    methods: {
        // ---------------事件处理函数---------------
        // 修改导航
        changeNav(index) {
            this.currentIndex = index;

            this.startTime = "";
            this.endTime = "";

            this.page = 1;
            this.loadData(this.page);
        },
        // 修改时间
        changeTime({ current, startTime, endTime }) {
            this.currentIndex = current;
            this.startTime = startTime;
            this.endTime = endTime;

            this.page = 1;
            this.loadData(this.page);
        },
        // 切换分页
        changePage({ current }) {
            this.page = current;

            this.loadData(this.page);
        },
        loadData(page) {
            let params = {
                startTime: this.startTime,
                endTime: this.endTime,
            };

            switch (+this.currentIndex) {
                case 0:
                    params["type"] = 0;
                    break;
                case 1:
                    params["type"] = 1;
                    break;
                case 2:
                    params["type"] = 7;
                    break;
                case 3:
                    params["type"] = 30;
                    break;
                case 4:
                    params["type"] = -1;
                    break;
            }

            this._getMachineData(page, this.pageSize, params);
        },

        // ----------------网络请求----------------
        // 获取商家统计
        _getMachineData(pageNumber, pageSize, params = {}) {
            this.loading = true;

            getMachineData(pageNumber, pageSize, params)
                .then((res) => {
                    const data = res.data;

                    this.totalSize = res.totalSize;
                    this.page = res.currentSize > 0 ? pageNumber : this.page;

                    let newList = [];

                    data.forEach((item, index) => {
                        let obj = {
                            top: (this.page - 1) * 5 + (+index + 1),
                            machine: item.deviceName,
                            business: item.operatorName,
                            sale: `￥${ item.salesAmount }`,
                            order: item.ordersAmount,
                        };

                        newList.push(obj);
                    });

                    this.loading = false;
                    this.list = newList;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
}
</script>

<style lang="less" scoped>
.machine-module {
    width: 100%;
}
</style>