<template>
    <div id="data-overview">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 全部统计 -->
        <total-data />

        <!-- 各模块数据 -->
        <div class="module-data-wrapper">
            <!-- 商家排行 -->
            <business-module />

            <!-- 商品排行 -->
            <goods-module />

            <!-- 门店排行 -->
            <store-module />

            <!-- 货柜排行 -->
            <machine-module />
        </div>
    </div>
</template>

<script>
import TotalData from './childComps/TotalData'
import BusinessModule from './childComps/BusinessModule'
import GoodsModule from './childComps/GoodsModule'
import StoreModule from './childComps/StoreModule'
import MachineModule from './childComps/MachineModule'

import Breadcrumb from 'components/breadcrumb/Breadcrumb'

export default {
    name: "DataOverview", // 数据概览
    components: {
        TotalData,
        BusinessModule,
        GoodsModule,
        StoreModule,
        MachineModule,

        Breadcrumb
    },
    data() {
        return {
            breadcrumb: [
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: "/dataOverview",
                    breadcrumbName: "数据概览",
                },
            ],
        }
    }
}
</script>

<style lang="less" scoped>
#data-overview {
    width: 100%;
    position: relative;

    .module-data-wrapper {
        margin: 0 20px 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        grid-gap: 20px 30px;
    }
}
</style>