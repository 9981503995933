<template>
    <div class="total-data-wrapper">
        <a-radio-group
            :value="currentIndex"
            :style="{ marginBottom: '10px' }"
            @change="changeNav"
        >
            <a-radio-button value="0"> 全部 </a-radio-button>
            <a-radio-button value="1"> 今天 </a-radio-button>
            <a-radio-button value="2"> 近7天 </a-radio-button>
            <a-radio-button value="3"> 近30天 </a-radio-button>
            <a-radio-button value="4"> 自定义时间 </a-radio-button>

            <div class="date-time">
                <span>自定义时间：</span>

                <a-range-picker :show-time="{ format: 'HH:mm:ss' }"
                                format="YYYY-MM-DD HH:mm:ss"
                                :placeholder="['开始日期', '结束日期']"
                                @ok="changeDateTime" />
            </div>
            
        </a-radio-group>

        <a-descriptions layout="vertical" bordered :column="5">
            <template v-for="(item, index) in totalData[ currentIndex ]">
                <a-descriptions-item :label="item.title" :key="index">
                    {{ item.unit + item.value }}
                </a-descriptions-item>
            </template>
        </a-descriptions>
            
    </div>
</template>

<script>
import { getTotalData, getTotalSale } from "network/dataManage";

export default {
    name: "TotalData",
    data() {
        return {
            totalData: [],

            currentIndex: "0",

            startTime: '',
            endTime: ''
        };
    },
    created() {
        this._getTotalData();
    },
    methods: {
        // --------------事件处理函数--------------
        // 修改导航
        changeNav(e) {
            const current = e.target.value;

            if (current < 4) {
                this.currentIndex = current;

                this._getTotalData();
            }
        },

        // 选择日期
        changeDateTime(dates) {
            this.currentIndex = '4';

            this.startTime = dates[0].format("YYYY-MM-DD HH:mm:ss");
            this.endTime = dates[1].format("YYYY-MM-DD HH:mm:ss");

            this._getTotalData(this.startTime, this.endTime);
        },

        // -----------------网络请求---------------
        // 获取全部统计
        _getTotalData(startTime = "", endTime = "") {
            Promise.all([
                getTotalSale(startTime, endTime),
                getTotalData(startTime, endTime),
            ])
                .then((res) => {
                    const [ data01, data02 ] = res;

                    let saleInfo = [];

                    // 销售统计数据信息
                    class SaleInfoItem {
                        constructor(title, unit, value) {
                            this.title = title;
                            this.unit = unit;
                            this.value = value;
                        }
                    }

                    // 全部
                    let totalSales = new SaleInfoItem("累计销售额", "￥", data01.data.totalSales),
                        totalOrders = new SaleInfoItem("累计销售量", "", data01.data.totalOrders),
                        totalWaitPayMoney = new SaleInfoItem("待支付金额", "￥", data01.data.totalUnpaidAmount),
                        totalWaitPayOrders = new SaleInfoItem("待支付订单", "", data01.data.totalUnpaidOrder),
                        totalCloseMoney = new SaleInfoItem("已关闭金额", "￥", data01.data.totalClosedAmount),
                        totalCloseOrders = new SaleInfoItem("已关闭订单", "", data01.data.totalClosedOrder),

                        totalUserNum = new SaleInfoItem("累计用户数", "", data02.data.totalUser),
                        totalBusinessNum = new SaleInfoItem("商家总数", "", data02.data.totalOperator),
                        totalGoodsNum = new SaleInfoItem("商品总数", "", data02.data.totalGoods),
                        totalMachineNum = new SaleInfoItem("货柜总数", "", data02.data.totalDevice);

                    saleInfo.push([
                        totalSales,
                        totalOrders,
                        totalWaitPayMoney,
                        totalWaitPayOrders,
                        totalCloseMoney,
                        totalCloseOrders,
                        totalUserNum,
                        totalBusinessNum,
                        totalGoodsNum,
                        totalMachineNum
                    ]);

                    // 今日
                    let todaySales = new SaleInfoItem("累计销售额", "￥", data01.data.todaySales),
                        todayOrders = new SaleInfoItem("累计销售量", "", data01.data.todayOrders),
                        todayWaitPayMoney = new SaleInfoItem("待支付金额", "￥", data01.data.todayUnpaidAmount),
                        todayWaitPayOrders = new SaleInfoItem("待支付订单", "", data01.data.todayUnpaidOrder),
                        todayCloseMoney = new SaleInfoItem("已关闭金额", "￥", data01.data.todayClosedAmount),
                        todayCloseOrders = new SaleInfoItem("已关闭订单", "", data01.data.todayClosedOrder),

                        todayUserNum = new SaleInfoItem("累计用户数", "", data02.data.todayUser),
                        todayBusinessNum = new SaleInfoItem("商家总数", "", data02.data.todayOperator),
                        todayGoodsNum = new SaleInfoItem("商品总数", "", data02.data.todayGoods),
                        todayMachineNum = new SaleInfoItem("货柜总数", "", data02.data.todayDevice);

                    saleInfo.push([
                        todaySales,
                        todayOrders,
                        todayWaitPayMoney,
                        todayWaitPayOrders,
                        todayCloseMoney,
                        todayCloseOrders,
                        todayUserNum,
                        todayBusinessNum,
                        todayGoodsNum,
                        todayMachineNum
                    ]);

                    // 近7天
                    let daySales7 = new SaleInfoItem("累计销售额", "￥", data01.data.days7Sales),
                        dayOrders7 = new SaleInfoItem( "累计销售量", "", data01.data.days7Orders),
                        dayWaitPayMoney7 = new SaleInfoItem("待支付金额", "￥", data01.data.days7UnpaidAmount),
                        dayWaitPayOrders7 = new SaleInfoItem("待支付订单", "", data01.data.days7UnpaidOrder),
                        dayCloseMoney7 = new SaleInfoItem("已关闭金额", "￥", data01.data.days7ClosedAmount),
                        dayCloseOrders7 = new SaleInfoItem("已关闭订单", "", data01.data.days7ClosedOrder),

                        dayUserNum7 = new SaleInfoItem("累计用户数", "", data02.data.days7User),
                        dayBusinessNum7 = new SaleInfoItem("商家总数", "", data02.data.days7Operator),
                        dayGoodsNum7 = new SaleInfoItem("商品总数", "", data02.data.days7Goods),
                        dayMachineNum7 = new SaleInfoItem("货柜总数", "", data02.data.days7Device);

                    saleInfo.push([
                        daySales7,
                        dayOrders7,
                        dayWaitPayMoney7,
                        dayWaitPayOrders7,
                        dayCloseMoney7,
                        dayCloseOrders7,
                        dayUserNum7,
                        dayBusinessNum7,
                        dayGoodsNum7,
                        dayMachineNum7
                    ]);

                    // 近30天
                    let daySales30 = new SaleInfoItem("销售额", "￥", data01.data.days30Sales),
                        dayOrders30 = new SaleInfoItem("订单量", "", data01.data.days30Orders),
                        dayWaitPayMoney30 = new SaleInfoItem("待支付金额", "￥", data01.data.days30UnpaidAmount),
                        dayWaitPayOrders30 = new SaleInfoItem("待支付订单", "", data01.data.days30UnpaidOrder),
                        dayCloseMoney30 = new SaleInfoItem("已关闭金额", "￥", data01.data.days30ClosedAmount),
                        dayCloseOrders30 = new SaleInfoItem("已关闭订单", "", data01.data.days30ClosedOrder),

                        dayUserNum30 = new SaleInfoItem("累计用户数", "", data02.data.days30User),
                        dayBusinessNum30 = new SaleInfoItem("商家总数", "", data02.data.days30Operator),
                        dayGoodsNum30 = new SaleInfoItem("商品总数", "", data02.data.days30Goods),
                        dayMachineNum30 = new SaleInfoItem("货柜总数", "", data02.data.days30Device);

                    saleInfo.push([
                        daySales30,
                        dayOrders30,
                        dayWaitPayMoney30,
                        dayWaitPayOrders30,
                        dayCloseMoney30,
                        dayCloseOrders30,
                        dayUserNum30,
                        dayBusinessNum30,
                        dayGoodsNum30,
                        dayMachineNum30
                    ]);

                    // 自定义时间
                    let timeSales = new SaleInfoItem("累计销售额", "￥", data01.data.timeSales),
                        timeOrders = new SaleInfoItem("累计销售量", "", data01.data.timeOrders),
                        timeWaitPayMoney = new SaleInfoItem("待支付金额", "￥", data01.data.timeUnpaidAmount),
                        timeWaitPayOrders = new SaleInfoItem("待支付订单", "", data01.data.timeUnpaidOrder),
                        timeCloseMoney = new SaleInfoItem("已关闭金额", "￥", data01.data.timeClosedAmount),
                        timeCloseOrders = new SaleInfoItem("已关闭订单", "", data01.data.timeClosedOrder),

                        timeUserNum = new SaleInfoItem("累计用户数", "", data02.data.timeUser),
                        timeBusinessNum = new SaleInfoItem("商家总数", "", data02.data.timeOperator),
                        timeGoodsNum = new SaleInfoItem("商品总数", "", data02.data.timeGoods),
                        timeMachineNum = new SaleInfoItem("货柜总数", "", data02.data.timeDevice);

                    saleInfo.push([
                        timeSales,
                        timeOrders,
                        timeWaitPayMoney,
                        timeWaitPayOrders,
                        timeCloseMoney,
                        timeCloseOrders,
                        timeUserNum,
                        timeBusinessNum,
                        timeGoodsNum,
                        timeMachineNum
                    ]);


                    this.totalData = saleInfo
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="less" scoped>
.total-data-wrapper {
    margin: 0 20px 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;

    .date-time {
        display: inline-block;
        margin-left: 20px;
    }
}
</style>